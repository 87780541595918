//Stops transition when resizing to prevent bug on navigation
(function () {
    const classes = document.body.classList;
    let timer = 0;

    window.addEventListener("resize", function () {
        if (timer) {
            clearTimeout(timer);
            timer = null;
        } else classes.add("stop-transitions");

        timer = setTimeout(() => {
            classes.remove("stop-transitions");
            timer = null;
        }, 100);
    });
})();

$(document).ready(function () {
    $(".slider__home").on("afterChange", function (event, slick, currentSlide) {
        var video = document.getElementById("video");
        if (currentSlide == 2) {
            video.play();
        } else {
            video.pause();
        }
    });

    $(".btn__red").on("click", function () {
        var button = $(this).text();
        if (button.trim() === "Volunteer") {
            $("#affected").fadeOut();
            $(".covid__content--affected").fadeOut();
            setTimeout(function () {
                $(".covid__contents").css(
                    "height",
                    $(".covid__content--volunteer").outerHeight(true)
                );
                $("#volunteer").fadeIn();
                $(".covid__content--volunteer").fadeIn();
                $(".covid__content--volunteer").addClass("isOpen");
            }, 500);
        } else {
            $("#volunteer").fadeOut();
            $(".covid__content--volunteer").fadeOut();
            $(".covid__content--volunteer").removeClass("isOpen");
            setTimeout(function () {
                $(".covid__contents").removeAttr("style");
                $("#affected").fadeIn();
                $(".covid__content--affected").fadeIn();
            }, 500);
        }
        $('html, body').animate({ scrollTop: 0 }, 800);
        $(".btn__red").toggleClass("btn__red--selected");
    });

    $("svg").on("click", function () {
        $(".covid__form").removeAttr("style");
        $(".covid__form__content").fadeIn();
        $(".covid__form__successful").css("display", "none");
        $(".covid__form__successful").fadeOut();
        $("#formAffected")[0].reset();
        $("#formVolunteer")[0].reset();
    });

    var $covid__icons = $('.covid__icons');

    if ('ontouchstart' in window || 'ontouch' in window) {
        $covid__icons.addClass('touch-device');
    } else {
        $covid__icons.addClass('non-touch-device');
    }

    var prevClass = '';
    var size = 0;
    var initial = $(".covid__icons").outerHeight(true);

    if ($covid__icons.hasClass("touch-device")) {
        $(".covid__icons__item").on("click", function () {
            if ((prevClass != $(this).attr('class').split(' ')[1]) && (prevClass !== '')) {
                $("." + prevClass).find("div").slideToggle();
            }
            size = initial + $(this).find("div").outerHeight(true);
            $(".covid__icons").css("height", size);
            prevClass = $(this).attr('class').split(' ')[1];
            $(this).find("div").slideToggle();
            size = 0;
        });
    } else {
        $(".covid__icons__item").mouseenter(function () {
            size = initial + $(this).find("div").outerHeight(true);
            $(".covid__icons").css("height", size);
            $(this).find('div').stop(true, true).slideToggle();
            size = 0;
        }).mouseleave(function () {
            $(this).find('div').stop(true, true).slideToggle();
            $(".covid__icons").removeAttr('style');
        });
    }
});

$(window).resize(function () {
    if ($(".covid__content--volunteer").hasClass("isOpen")) {
        $(".covid__contents").css("height", $(".covid__content--volunteer").outerHeight(true));
    }
});
